import React, { useEffect, useContext } from 'react'
import './InsertGroup.scss'
import addIcon from '../../../images/icons/add.svg';
import { ElementsContext } from '../../../contexts/ElementsContext';
import { nanoid } from 'nanoid';
import setFocusTo from '../../../functions/setFocusTo';
import defaultElementProps from '../../../lib/defaultElementProps';


export default function InsertGroup(props) {

    let { index, element, alwaysVisible, focusElement, insertShortcut } = props;
    const { elements, setElements, isReordering } = useContext(ElementsContext);

    const addElement = (newElement) => {
        let newElementId = nanoid();
        newElement.id = newElementId;
        let currentElements = [...elements];

        let splicedElements = currentElements.splice(index + 1, 0, newElement);

        setElements(currentElements);
        setFocusTo(newElement);
    };

    const addImageElements = () => {
        let newElements = [{
            id: nanoid(),
            ...defaultElementProps,
            editorLabel: 'Image URL',
            previewLabel: 'Image for reference',
            previewElement: 'image',
            showWordCount: false,
        }, {
            id: nanoid(),
            ...defaultElementProps,
            editorLabel: 'Image Description',
            previewLabel: 'Image Description',
        }];


        let currentElements = [...elements];
        let splicedArray = currentElements.splice(index + 1, 0, ...newElements);

        setElements(currentElements);
        setFocusTo(newElements[0]);
    }

    const addVideoElements = () => {
        let newElements = [{
            id: nanoid(),
            ...defaultElementProps,
            editorLabel: 'Video link',
            previewLabel: 'Video link',
            previewElement: 'video',
            showWordCount: false,
        }, {
            id: nanoid(),
            ...defaultElementProps,
            editorLabel: 'Video Description',
            previewLabel: 'Video Description',
            previewElement: 'block',
        }];

        let currentElements = [...elements];
        let splicedArray = currentElements.splice(index + 1, 0, ...newElements);

        setElements(currentElements);
        setFocusTo(newElements[0]);
    }

    const addHeadingElement = () => {
        let newElement = {
            ...defaultElementProps,
            editorLabel: 'Heading',
            previewLabel: 'Heading',
        };
        addElement(newElement);
    }

    const addSubheadingElement = () => {
        let newElement = {
            ...defaultElementProps,
            editorLabel: 'Subheading',
            previewLabel: 'Subheading',
        };
        addElement(newElement);
    }

    const addParagraphElement = () => {
        let newElement = {
            ...defaultElementProps,
            editorLabel: 'Paragraph',
        };
        addElement(newElement);
    }

    const addCtaElement = () => {
        let newElement = {
            ...defaultElementProps,
            editorLabel: 'CTA',
            previewLabel: 'CTA',
        };
        addElement(newElement);
    }

    const addDividerElement = () => {
        let newElement = {
            ...defaultElementProps,
            editorLabel: 'Divider',
            editorElement: 'divider',
            previewLabel: null,
            previewElement: 'divider',
            isDuplicatable: false,
            isHideable: false,
            isHidden: false,
        };

        delete newElement.isHidden;

        addElement(newElement);
    }

    const addTitleElement = () => {
        let newElement = {
            ...defaultElementProps,
            editorLabel: 'Title',
            previewLabel: 'Title',
        };
        addElement(newElement);
    }

    const addSubtitleElement = () => {
        let newElement = {
            ...defaultElementProps,
            editorLabel: 'Subtitle',
            previewLabel: 'Subtitle',
        };
        addElement(newElement);
    }

    const addBlockquoteElement = () => {
        let newElement = {
            ...defaultElementProps,
            editorLabel: 'Blockquote',
            previewLabel: 'Blockquote',
        };
        addElement(newElement);
    }

    const addLinkElement = () => {
        let newElement = {
            ...defaultElementProps,
            editorLabel: 'Link URL',
            previewLabel: 'Link',
            previewElement: 'link',
            showWordCount: false,
        };
        addElement(newElement);
    }

    const addNoteElement = () => {
        let newElement = {
            ...defaultElementProps,
            editorLabel: 'Note',
            previewLabel: 'Note',
            previewElement: 'note',
        };
        addElement(newElement);
    }

    const addListElement = () => {
        let newElement = {
            ...defaultElementProps,
            editorLabel: 'List',
            previewLabel: 'List',
            previewElement: 'list',
        };
        addElement(newElement);
    }

    const handleKeyboardShortcuts = (key) => {
        switch (key) {
            case 'KeyH':
                addHeadingElement();
                break;
            case 'KeyU':
                addSubheadingElement();
                break;
            case 'KeyP':
                addParagraphElement();
                break;
            case 'KeyC':
                addCtaElement();
                break;
            case 'KeyI':
                addImageElements();
                break;
            case 'KeyV':
                addVideoElements();
                break;
            case 'KeyD':
                addDividerElement();
                break;
            case 'KeyT':
                addTitleElement();
                break;
            case 'KeyE':
                addSubtitleElement();
                break;
            case 'KeyB':
                addBlockquoteElement();
                break;
            case 'KeyK':
                addLinkElement();
                break;
            case 'KeyN':
                addNoteElement();
                break;
            case 'KeyL':
                addListElement();
                break;
            default:
                break;
        }
    }

    const handleKeyDown = (e) => {
        e.preventDefault();

        if (e.code === 'Escape') {
            setFocusTo(focusElement ? focusElement : element);
        }
        else {
            if (e.shiftKey) return;
            handleKeyboardShortcuts(e.code);
        }
    }

    useEffect(() => {
        if (!insertShortcut) return;
        handleKeyboardShortcuts(insertShortcut);
    }, [insertShortcut]);

    return (
        <div
            className={`insert-group ${alwaysVisible ? 'always-visible' : ''} ${isReordering ? 'hidden' : ''}`}
            onKeyDown={handleKeyDown}
            tabIndex="-1"
            title='Insert element'
        >
            <button
                className="insert-button"
                tabIndex="-1"
                title='Insert element'
            >
                <img src={addIcon} alt="add" loading="eager" />
            </button>

            <div className='insert-menu-list'>

                <button
                    tabIndex="-1"
                    onClick={addTitleElement}
                    className="list-item"
                    data-shortcut="Ctrl + T"
                >
                    <u>T</u>itle
                </button>

                <button
                    tabIndex="-1"
                    onClick={addSubtitleElement}
                    className="list-item"
                    data-shortcut="Ctrl + E"
                >
                    Subtitl<u>e</u>
                </button>

                <div className="list-group-divider"></div>

                <button
                    tabIndex="-1"
                    onClick={addHeadingElement}
                    className="list-item"
                    data-shortcut="Ctrl + H"
                >
                    <u>H</u>eading
                </button>

                <button
                    tabIndex="-1"
                    onClick={addSubheadingElement}
                    className="list-item"
                    data-shortcut="Ctrl + U"
                >
                    S<u>u</u>bheading
                </button>

                <button
                    tabIndex="-1"
                    onClick={addParagraphElement}
                    className="list-item"
                    data-shortcut="Ctrl + P"
                >
                    <u>P</u>aragraph
                </button>

                <button
                    tabIndex="-1"
                    onClick={addCtaElement}
                    className="list-item"
                    data-shortcut="Ctrl + C"
                >
                    <u>C</u>TA
                </button>

                <button
                    tabIndex="-1"
                    onClick={addLinkElement}
                    className="list-item"
                    data-shortcut="Ctrl + K"
                >
                    Lin<u>k</u>
                </button>

                <div className="list-group-divider"></div>

                <button
                    tabIndex="-1"
                    onClick={addImageElements}
                    className="list-item"
                    data-shortcut="Ctrl + I"
                >
                    <u>I</u>mage
                </button>

                <button
                    tabIndex="-1"
                    onClick={addVideoElements}
                    className="list-item"
                    data-shortcut="Ctrl + V"
                >
                    <u>V</u>ideo
                </button>

                <div className="list-group-divider"></div>

                <button
                    tabIndex="-1"
                    onClick={addDividerElement}
                    className="list-item"
                    data-shortcut="Ctrl + D"
                >
                    <u>D</u>ivider
                </button>

                <div className="list-group-divider"></div>

                <button
                    tabIndex="-1"
                    onClick={addBlockquoteElement}
                    className="list-item"
                    data-shortcut="Ctrl + B"
                >
                    <u>B</u>lockquote
                </button>

                <button
                    tabIndex="-1"
                    onClick={addNoteElement}
                    className="list-item"
                    data-shortcut="Ctrl + N"
                >
                    <u>N</u>ote
                </button>

                <button
                    tabIndex="-1"
                    onClick={addListElement}
                    className="list-item"
                    data-shortcut="Ctrl + L"
                >
                    <u>L</u>ist
                </button>
            </div>
        </div>
    )
}
