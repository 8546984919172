import React, { useState } from 'react';
import './LoginBlock.scss';
import { motion } from 'framer-motion';
import { useAuth } from '../../contexts/AuthContext';

const LoginBlock = () => {
    const { signInWithGoogle } = useAuth();

    const [clicked, setClicked] = useState(false);

    const handleLoginButtonClick = () => {
        if (clicked) return;

        setClicked(current => true);
        signInWithGoogle();
    }

    return (
        <motion.section className="login-block"
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.4, ease: "circOut" }}
        >
            <div className="login-block-wrapper">
                <h2>Welcome. Please sign in.</h2>

                <button
                    onClick={handleLoginButtonClick}
                    className={clicked ? 'loading' : ''}
                    disabled={clicked ? 'disabled' : ''}
                >
                    <span>
                        {clicked ? '' : 'Sign in with Google'}
                    </span>
                </button>
            </div>
        </motion.section>
    );
}

export default LoginBlock;
