const setFocusTo = (element) => {
    if (!element) return;
    setTimeout(() => {
        let targetElement = document.getElementById(element.id);
        if(!targetElement) return;
        targetElement.focus();
        targetElement.scrollIntoView({ block: "nearest" });

        let previewElement = document.querySelector(`[data-editor-id='${element.id}']`)
        if (!previewElement) return;
        previewElement.scrollIntoView({ block: "start" });
    }, 50);
};

export default setFocusTo;