const defaultElementProps = {
    value: '',
    editorLabel: 'Paragraph',
    editorElement: 'input',
    previewLabel: 'Paragraph',
    previewElement: 'text',
    showWordCount: true,
    autoFocus: true,
    isGroupChild: false,
    isRemovable: true,
    isDuplicatable: true,
    isHideable: true,
    isHidden: false,
    isCopiable: true,
}

export default defaultElementProps;