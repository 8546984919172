import React, { useContext } from 'react';
import duplicateIcon from '../../images/icons/duplicate.svg';
import { nanoid } from 'nanoid';
import { ElementsContext } from '../../contexts/ElementsContext';
import setFocusTo from '../../functions/setFocusTo';

const DuplicateButton = ({ index, element }) => {

    const { elements, setElements } = useContext(ElementsContext);

    const duplicateElement = () => {
        if (!element.isDuplicatable) return;

        let newElementId = nanoid();
        let newElement = {
            ...element,
            id: newElementId,
        }

        let currentElements = [...elements];

        // duplicating an individual element
        let splicedElements = currentElements.splice(index + 1, 0, newElement);

        setElements(currentElements);
        setFocusTo(newElement);
    };

    return (
        <button className="icon-button duplicate-button"
            tabIndex={-1}
            onClick={duplicateElement}
            data-tooltip='Duplicate'
        >
            <img src={duplicateIcon} alt="Duplicate" loading="eager" />
        </button>
    );
}

export default DuplicateButton;
