import React, { useEffect } from 'react';
import './index.scss';

const TogglePreviewButton = ({ previewHidden, setPreviewHidden }) => {

    useEffect(() => {
        localStorage.setItem('preview_hidden', previewHidden);
    }, [previewHidden]);

    const togglePreview = () => {
        setPreviewHidden(prev => (!prev));
    }

    return (
        <div
            className={previewHidden ? 'preview-toggle-block' : 'preview-toggle-block active'}
            title={previewHidden ? 'Show Preview [Ctrl + Alt/Opt + Shift + P]' : 'Hide Preview [Ctrl + Alt/Opt + Shift + P]'}
            onClick={togglePreview}
        >
            <div className="divider"></div>
            <div className='preview-icon'></div>
        </div>
    );
}

export default TogglePreviewButton;
