import React, { useContext } from 'react';

const PreviewBlock = ({ element }) => {

    if (element.isHidden) {
        return null;
    }

    if (!element.value?.trim().length > 0) {
        return null;
    }

    return (
        <>
            <p data-editor-id={element.id} className="preview-block">
                <span className='preview-block-label'>{element.previewLabel ? element.previewLabel : element.editorLabel}: </span>
                <span>
                    {element.value.trim()}
                </span>
            </p>
        </>
    )
}

export default PreviewBlock;
