import React, { useEffect, useRef, useState, useContext } from 'react'
import makeHttpsUrl from '../../../functions/makeHttpsUrl';
import { ElementsContext } from '../../../contexts/ElementsContext';

const PreviewLink = ({ element }) => {
    const { styledPreview } = useContext(ElementsContext);

    const srcTimerRef = useRef();
    const [linkSrc, setLinkSrc] = useState(makeHttpsUrl(element.value.trim()));

    useEffect(() => {
        if (!element.value.trim()) return;

        clearTimeout(srcTimerRef.current);
        let url = makeHttpsUrl(element.value.trim());

        if (linkSrc === url) return;

        srcTimerRef.current = setTimeout(() => {
            setLinkSrc(url);
        }, 600);

        return () => {
            clearTimeout(srcTimerRef.current);
        };
    }, [element.value]);

    if (element.isHidden) {
        return null;
    }

    if (!element.value?.trim().length > 0) {
        return null;
    }

    let elementClassname = "preview-block link-block";

    if (styledPreview) {
        elementClassname = "preview-block preview-block-link link-block";
    }

    return (
        <div data-editor-id={element.id} className={elementClassname}>
            <>
                {!styledPreview && (
                    <span className='preview-block-label'>{element.previewLabel ? element.previewLabel : element.editorLabel}: </span>
                )}

                <img src={`https://www.google.com/s2/favicons?domain=${linkSrc}&sz=32`} className="link-block-favicon" alt="" loading="eager" draggable="false" />

                <a href={linkSrc}
                    target="_blank"
                    title="Open link in a new tab"
                    rel="noreferrer"
                >
                    {linkSrc}
                </a>
            </>
        </div>
    )
}

export default PreviewLink;
