import React, { useState, useRef, useEffect } from 'react';
import './ShareDialog.scss';
import mangocopySymbol from '../../images/identity/mangocopy_symbol.svg';
import { useNavigate } from 'react-router-dom';
import twitterIcon from '../../images/icons/social/twitter.svg';
import linkedinIcon from '../../images/icons/social/linkedin.svg';
import facebookIcon from '../../images/icons/social/facebook.svg';
import whatsappIcon from '../../images/icons/social/whatsapp.svg';
import backArrowIcon from '../../images/icons/back_arrow.svg';
import closeIcon from '../../images/icons/remove.svg';
import copyIcon from '../../images/icons/copy.svg';
import copiedIcon from '../../images/icons/copied.svg';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import { motion, AnimatePresence } from 'framer-motion';

const shareUrl = "https://mangocopy.app";
const shareMessage = "The copy editor designed to bring out the best in you. Write with Mangocopy.";

export default function ShareDialog({ open, setShareDialogVisible }) {
    const navigate = useNavigate();
    const dialogLinksRef = useRef();
    const isMobile = useMediaQuery('(max-width: 1000px)');

    useEffect(() => {
        if (!open) return;
        if (!dialogLinksRef.current) return;

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, [open, dialogLinksRef]);

    const handleClose = () => {
        navigate(-1);
        setShareDialogVisible(false);
    }

    const handleKeyDown = (e) => {
        if (e.code !== "Escape") return;
        handleClose();
    }

    const [linkCopied, setLinkCopied] = useState(false);

    const handleCopyLinkClick = () => {
        navigator.clipboard.writeText(shareUrl).then(() => {
            setLinkCopied(current => true);
        });
    }

    useEffect(() => {
        if (!linkCopied) return;

        let copyLinkWatcher = setTimeout(() => {
            setLinkCopied(current => false);
        }, 2000);

        return () => {
            clearTimeout(copyLinkWatcher);
        }
    }, [linkCopied]);

    // dialog animations
    let initialTransform = isMobile ? '5%' : '-10%';
    let bounceEase = [0.4, -0.01, 0, 1.31];

    const dialogVariants = {
        initial: {
            opacity: 0,
            y: initialTransform,
        },
        animate: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 0.6,
                ease: bounceEase
            },
        },
        exit: {
            opacity: 0,
            y: initialTransform,
            transition: {
                duration: 0.2,
                ease: bounceEase
            },
        },
    }

    const dialogSkrimVariants = {
        initial: {
            opacity: 0,
        },
        animate: {
            opacity: 1,
            y: 0,

        },
        exit: {
            opacity: 0,
        },
        transition: {
            duration: 0.8,
            ease: bounceEase
        },
    }

    return (
        <AnimatePresence mode='sync'>
            {open && (
                <>
                    <motion.div
                        className="share-dialog-skrim"
                        onClick={handleClose}
                        onKeyDown={handleKeyDown}
                        variants={dialogSkrimVariants}
                        initial='initial'
                        animate='animate'
                        exit='exit'
                    >
                    </motion.div>

                    <motion.dialog
                        className='share-dialog'
                        onKeyDown={handleKeyDown}
                        variants={dialogVariants}
                        initial='initial'
                        animate='animate'
                        exit='exit'
                    >

                        {open && (
                            <>
                                {isMobile ? (
                                    <button className="close-btn" title="Close" onClick={handleClose}>
                                        <img src={closeIcon} alt="Close" />
                                    </button>
                                ) : (
                                    <button className="back-btn" title="Back" onClick={handleClose}>
                                        <img src={backArrowIcon} alt="Back" />
                                    </button>
                                )}
                            </>
                        )}

                        <div className="intro">
                            <img src={mangocopySymbol} alt="" className='logo' />
                            <h2>Share Mangocopy</h2>
                            <p>Invite your friends to use Mangocopy</p>
                        </div>

                        <div className="share-links"
                            ref={dialogLinksRef}
                            tabIndex="0"
                        >
                            <a href={`https://twitter.com/intent/tweet?url=${shareUrl}&text=${shareMessage}`}
                                target="_blank"
                                rel="noreferrer"
                                title="Share Mangocopy on Twitter"
                                className="share-link"
                            >
                                <img src={twitterIcon} alt="" />
                                <span>Twitter</span>
                            </a>
                            <a href={`https://www.linkedin.com/shareArticle?url=${shareUrl}&title=${shareMessage}`}
                                target="_blank"
                                rel="noreferrer"
                                title="Share Mangocopy on LinkedIn"
                                className="share-link"
                            >
                                <img src={linkedinIcon} alt="" />
                                <span>LinkedIn</span>
                            </a>
                            <a href={`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`}
                                target="_blank"
                                rel="noreferrer"
                                title="Share Mangocopy on Facebook"
                                className="share-link"
                            >
                                <img src={facebookIcon} alt="" />
                                <span>Facebook</span>
                            </a>
                            <a href={`https://web.whatsapp.com/send?text=${shareMessage} ${shareUrl}`}
                                target="_blank"
                                rel="noreferrer"
                                title="Share Mangocopy on WhatsApp"
                                className="share-link"
                            >
                                <img src={whatsappIcon} alt="" />
                                <span>WhatsApp</span>
                            </a>
                            <button
                                className="share-link"
                                onClick={handleCopyLinkClick}
                            >
                                <img src={linkCopied ? copiedIcon : copyIcon} alt="" />
                                <span>
                                    {linkCopied ? 'Link copied' : 'Copy Link'}
                                </span>
                            </button>
                        </div>
                    </motion.dialog>
                </>
            )}
        </AnimatePresence>
    )
}