import React from 'react';
import ReactDOM from 'react-dom/client';
import Main from './Main';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import "@fontsource/ibm-plex-serif/400.css";
import "@fontsource/ibm-plex-serif/500.css";
import "@fontsource/inter";
import "@fontsource/inter/400.css";
import "@fontsource/inter/500.css";
import "@fontsource/inter/600.css";
import "@fontsource/inter/700.css";
import "@fontsource/inter/800.css";
import './styles/master.scss'

import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';

const root = ReactDOM.createRoot(document.querySelector('mangocopy'));
root.render(
    <>
        <BrowserRouter>
            <ScrollToTop />
            <Main />
        </BrowserRouter>
    </>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
