import React, { useContext } from 'react';
import hideIcon from '../../images/icons/hide.svg';
import hiddenIcon from '../../images/icons/hidden.svg';
import { ElementsContext } from '../../contexts/ElementsContext';

const HideButton = ({ element }) => {

    const { elements, setElements } = useContext(ElementsContext);

    const hidden = element.isHidden;

    const toggleVisibility = () => {
        let currentElements = [...elements];
        let newValue = !element.isHidden;
        element.isHidden = newValue;

        setElements(currentElements);
    }

    return (
        <button className={`icon-button hide-button ${hidden ? 'active' : ''}`}
            tabIndex={-1}
            onClick={toggleVisibility}
            data-tooltip={hidden ? 'Show' : 'Hide'}>

            {hidden ? (
                <img src={hiddenIcon} alt="Hidden" loading="eager" />
            ) : (
                <img src={hideIcon} alt="Hide" loading="eager" />
            )}

        </button>
    );
}

export default HideButton;
