import React, { useContext } from 'react';
import { ElementsContext } from '../../../contexts/ElementsContext';

const PreviewList = ({ element }) => {
    const { styledPreview } = useContext(ElementsContext);
    return (
        <div className="preview-block list-block" data-editor-id={element.id}>
            {!element.isHidden && element.value?.trim().length > 0 && (
                <>
                    {!styledPreview && (
                        <span className='preview-block-label'>{element.previewLabel ? element.previewLabel : element.editorLabel}: </span>
                    )}

                    <ol>
                        {element.value.split("\n").map((item, index) => (
                            item.trim().length > 0 && (
                                <li key={index}>
                                    {item}
                                </li>
                            )
                        ))}
                    </ol>
                </>
            )}
        </div>
    )
}

export default PreviewList;
