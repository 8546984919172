import React from 'react';
import './Support.scss';
import { Link } from 'react-router-dom';
import handWaveEmoji from '../../images/emojis/hand_wave.png';
import guidesIllustration from '../../images/illustrations/guides.svg';
import conversationIllustration from '../../images/illustrations/conversation.svg';
import { motion } from 'framer-motion';

const currentHour = new Date().getHours();

export default function Support() {
    let greeting = "Hello";

    if (currentHour >= 5 && currentHour < 12) {
        greeting = "Good morning";
    }
    else if (currentHour > 11 && currentHour < 17) {
        greeting = "Good afternoon";
    }
    else if (currentHour > 16 && currentHour <= 23) {
        greeting = "Good evening";
    }
    else {
        greeting = "Hello";
    }

    return (
        <motion.main className="support-main"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ ease: "easeOut", duration: 0.2 }}
        >
            <div className="support-main-wrapper">
                <h1>{greeting} <img src={handWaveEmoji} alt="" loading="lazy" /> <br /> Need help?</h1>

                <div className="support-blocks">
                    <Link to="/guides" className='support-block'>
                        <span className="support-block-label">
                            Read the help&nbsp;guides
                        </span>
                        <div className="support-block-art">
                            <img src={guidesIllustration} alt="A purple colored book with a yellow colored bookmark" loading="eager" />
                        </div>
                    </Link>
                    <a href="mailto:hi@mangocopy.app" target="_blank" className='support-block'>
                        <span className="support-block-label">
                            Write us a&nbsp;message <br />hi@mangocopy.app
                        </span>
                        <div className="support-block-art">
                            <img src={conversationIllustration} alt="A conversation bubble on top of a yellow colored coffee mug" loading="eager" />
                        </div>
                    </a>
                </div>
            </div>
        </motion.main>
    )
}
