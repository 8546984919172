import React, { useEffect, useRef, useState, useContext } from 'react'
import makeHttpsUrl from '../../../functions/makeHttpsUrl';
import { ElementsContext } from '../../../contexts/ElementsContext';

export const PreviewImage = ({ element }) => {
    const { styledPreview } = useContext(ElementsContext);

    const srcTimerRef = useRef();
    const [imageSrc, setImageSrc] = useState(makeHttpsUrl(element.value.trim()));

    useEffect(() => {
        if (!element.value.trim()) return;

        clearTimeout(srcTimerRef.current);
        let url = makeHttpsUrl(element.value.trim());

        if (imageSrc === url) return;

        srcTimerRef.current = setTimeout(() => {
            setImageSrc(url);
        }, 600);

        return () => {
            clearTimeout(srcTimerRef.current);
        };
    }, [element.value]);

    if (element.isHidden) {
        return null;
    }

    if (!element.value?.trim().length > 0) {
        return null;
    }

    return (
        <div className='preview-block image-block'
            data-editor-id={element.id}
        >
            <>
                {styledPreview ? (
                    // empty placeholder, needed for image link to work in docs.
                    <b>&nbsp;</b>
                ) : (
                    <span className='preview-block-label'>{element.previewLabel ? element.previewLabel : element.editorLabel}: </span>
                )}

                <a href={imageSrc}
                    target="_blank"
                    title="Open image in a new tab"
                    rel="noreferrer"
                >
                    <img src={imageSrc}
                        alt=""
                        loading="eager"
                        draggable="false"
                    />
                </a>
            </>
        </div>
    )
}
export default PreviewImage;