import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Header from './components/Header';
import Home from './pages/Home';
import App from './pages/App';
import Guides from './pages/Guides';
import Support from './pages/Support';
import NotFound from './pages/NotFound';
import { AnimatePresence } from 'framer-motion';

import { AuthProvider } from './contexts/AuthContext';

const Main = () => {

    return (
        <>
            <AuthProvider>
                <Header />
                <AnimatePresence>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route exact path="/write/*" element={<App />} />
                        <Route exact path="/app/*" element={<App />} />
                        <Route path="/guides/*" element={<Guides />} />
                        <Route path="/support/*" element={<Support />} />
                        <Route path="/contact/*" element={<Support />} />
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                </AnimatePresence>
            </AuthProvider>
        </>
    );
}

export default Main;
