import React, { useContext } from 'react';
import { ElementsContext } from '../../../contexts/ElementsContext';

const PreviewText = ({ element }) => {
    const { styledPreview } = useContext(ElementsContext);

    if (element.isHidden) {
        return null;
    }

    if (!element.value?.trim().length > 0) {
        return null;
    }

    let elementClassname = "preview-block";

    if (styledPreview) {
        switch (element.editorLabel) {
            case "Heading":
                elementClassname = "preview-block preview-block-heading"
                break;
            case "Subheading":
                elementClassname = "preview-block preview-block-subheading"
                break;
            case "Paragraph":
                elementClassname = "preview-block preview-block-paragraph"
                break;
            case "CTA":
                elementClassname = "preview-block preview-block-cta"
                break;
            case "Title":
                elementClassname = "preview-block preview-block-title"
                break;
            case "Subtitle":
                elementClassname = "preview-block preview-block-subtitle"
                break;
            case "Blockquote":
                elementClassname = "preview-block preview-block-blockquote"
                break;
            case "Image Description":
                elementClassname = "preview-block preview-block-image-description"
                break;
            default:
                elementClassname = "preview-block"
                break;
        }
    }

    return (
        <>
            <p data-editor-id={element.id} className={elementClassname}>
                {!styledPreview && (
                    <span className='preview-block-label'>{element.previewLabel ? element.previewLabel : element.editorLabel}: </span>
                )}
                <span>
                    {styledPreview ? (element.value.trim()) : (element.value)}
                    <span className='cta-arrow'>
                        {styledPreview && element.editorLabel === "CTA" && (' »')}
                    </span>
                </span>
            </p>
        </>
    )
}

export default PreviewText;
