import React, { useState, useEffect, useRef } from 'react';
import copyIcon from '../../images/icons/copy.svg';
import copiedIcon from '../../images/icons/copied.svg';

const COPY_TEXT = 'Copy text';
const COPIED_TEXT = 'Copied';

const CopyTextButton = ({ element }) => {
    const [copied, setCopied] = useState(false);
    let copyTimer = useRef(null);

    const copyText = async () => {
        clearTimeout(copyTimer.current);

        await navigator.clipboard.writeText(element.value).then(() => {
            setCopied(current => true);
            copyTimer.current = setTimeout(() => {
                setCopied(current => false);
            }, 2000);
        });
    }

    useEffect(() => {
        if (!copied) return;

        return () => {
            clearTimeout(copyTimer.current);
        }
    }, [copied]);

    return (
        <button className={`icon-button copy-text-button ${copied ? 'active' : ''}`}
            tabIndex={-1}
            onClick={copyText}
            data-tooltip={copied ? COPIED_TEXT : COPY_TEXT}>

            {copied ? (
                <img src={copiedIcon} alt={COPIED_TEXT} loading="eager" />
            ) : (
                <img src={copyIcon} alt={COPY_TEXT} loading="eager" />
            )}

        </button>
    );
}

export default CopyTextButton;
