import React, { useState, useContext } from 'react';
import './DividerElement.scss'
import RemoveButton from '../../RemoveButton';
import InsertGroup from '../InsertGroup';
import { ElementsContext } from '../../../contexts/ElementsContext';
import { motion } from 'framer-motion';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

const DividerElement = ({ ...props }) => {
    const { index, element } = props;

    const { elements, setFocusedElement } = useContext(ElementsContext);

    // show insert group if this is the last element
    let showInsertGroup = false;
    if (!element.isGroupChild && elements.length - 1 === index) {
        showInsertGroup = true;
    }

    const handleFocus = () => {
        setFocusedElement(element.id);
    }

    const handleBlur = () => {
        setFocusedElement(null);
    }

    const [insertShortcut, setInsertShortcut] = useState(null);

    const handleKeyDown = (e) => {
        if (e.repeat) return;
        if (e.shiftKey) return;
        if (!e.ctrlKey || !e.altKey) return;
        e.preventDefault();
        setInsertShortcut(e.code);
    }

    const {
        setNodeRef,
        attributes,
        transform,
        isDragging,
    } = useSortable({
        id: element.id,
        transition: null,
    });

    return (
        <motion.div
            layout
            ref={setNodeRef}
            animate={
                transform
                    ? {
                        x: transform.x,
                        y: transform.y,
                    }
                    : {
                        x: 0,
                        y: 0,
                    }
            }
            transition={{
                duration: transform ? (isDragging ? 0 : 0.45) : 0.25,
                easings: {
                    type: 'tween',
                    duration: isDragging ? 0 : 0.6,
                },
            }}
            {...attributes}
            tabIndex={-1}
        >
            <div className="divider-group">
                <label htmlFor={element.id}>{element.editorLabel}</label>
                <motion.div className="divider"
                    id={element.id}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onKeyDown={handleKeyDown}
                    // onKeyUp={handleKeyUp}
                    tabIndex="-1"
                    initial={{ scaleX: 0, transformOrigin: "left", scaleY: 2, backgroundColor: '#000' }}
                    animate={{ scaleX: 1, transformOrigin: "left", scaleY: 1, backgroundColor: '#aaa' }}
                    transition={{ duration: 0.6, ease: [0.5, 0, 0.2, 1.025] }}
                ></motion.div>

                <div className="element-actions">
                    <RemoveButton
                        element={element} />
                </div>
            </div>
            <InsertGroup
                element={element}
                index={index}
                insertShortcut={insertShortcut}
                alwaysVisible={showInsertGroup}
            />
        </motion.div>
    );
}

export default DividerElement;
