import React from 'react';

const PreviewNote = ({ element }) => {
    return (
        <div className="preview-block note-block" data-editor-id={element.id}>
            {!element.isHidden && element.value?.trim().length > 0 && (
                element.value
            )}
        </div>
    )
}

export default PreviewNote;
