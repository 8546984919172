import React, { useContext, useState, useEffect, createContext } from 'react'
import { supabase } from '../supabase/client';

const AuthContext = createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {
    const [user, setUser] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // check active sessions and sets the user
        const session = supabase.auth.session();

        setUser(session?.user ?? null);
        setLoading(false);

        // listen for changes on auth state
        const { data: authListener } = supabase.auth.onAuthStateChange(async (event, session) => {
            setUser(session?.user ?? null);
            setLoading(false);
        });

        return () => {
            authListener?.unsubscribe();
        }
    }, []);

    const value = {
        signInWithGoogle: () => supabase.auth.signIn({ provider: 'google', refreshToken: supabase.auth.session?.refresh_token, }, {
            redirectTo: process.env.NODE_ENV === "production" ? 'https://mangocopy.app/write' : 'http://localhost:3000/write',
        }),
        signOut: () => supabase.auth.signOut(),
        user,
    }

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    )
}