import React from 'react'
import { motion } from 'framer-motion';
import './Guides.scss';

export default function Guides() {
    return (
        <>
            <motion.main
                className="guides-main"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ ease: "easeOut", duration: 0.2 }}
            >
                <div className="guides-main-wrapper">
                    <div className="introduction">
                        <h1>Guides</h1>
                        <h2>Learn more about Mangocopy</h2>
                    </div>
                    <section className="faq">
                        <h3>Overview</h3>
                        <ul>
                            <li>
                                <DetailsBlock
                                    summary="What is Mangocopy?"
                                    details={(
                                        <>
                                            <p>
                                                Mangocopy makes content writing for marketing easier by providing a document editor personalised for each marketing use case such as SEO articles, Facebook Ad copy, Google Ad copy, Landing page copy.
                                            </p>
                                            <p>
                                                We want to remove the pain of formatting and organizing their content drafts for content marketers and copywriters.
                                            </p>
                                            <p>
                                                You can use Mangocopy to create finished versions of your documents - or use it to create structured drafts for internal use amongst your team. Export everything you write in one click to your preffered platform by clicking on Copy all.
                                            </p>
                                        </>
                                    )}
                                />
                            </li>

                            <li>
                                <DetailsBlock
                                    summary="What can I write with Mangocopy?"
                                    details={(
                                        <>
                                            <p>
                                                With Mangocopy, you can create, develop and organize any type of marketing copy such as Landing pages, SEO articles, Facebook Ads, Google Ads and many more.
                                            </p>
                                            <p>
                                                The editor has been designed keeping in mind important tools that a marketer always needs easy access to - word count, duplicating elements to create multiple versions of the same content and a handy preview section to help you visualize how everything looks when it's put together.
                                            </p>
                                        </>
                                    )}
                                />
                            </li>

                            <li>
                                <DetailsBlock
                                    summary="What is Preview?"
                                    details={(
                                        <>
                                            <p>
                                                The preview section helps you visualize how your content looks as a whole, and you can keep track of it while you are writing.
                                            </p>
                                            <p>
                                                To increase focus and minimize distractions, you can also toggle the preview off, by clicking on the horizontal section in between the Editor and the Preview.
                                            </p>
                                            <p>
                                                You can scroll the Preview and Editor sections individually to keep track of output in real time.
                                            </p>
                                        </>
                                    )}
                                />
                            </li>

                            <li>
                                <DetailsBlock
                                    summary="How to insert new elements?"
                                    details={(
                                        <>
                                            <p>
                                                Mangocopy is designed to be marketer first, and you can insert different elements such as Heading, Subheading, CTA and Notes to your copy. Let's say you would like to insert a paragraph, click the plus button to open the insert menu and select Paragraph.
                                            </p>
                                        </>
                                    )}
                                />
                            </li>

                            <li>
                                <DetailsBlock
                                    summary="How do I hide an element?"
                                    details={(
                                        <>
                                            <p>
                                                If you don't want an element to be visible in Preview, you can click on the Hide button, which will hide that element. Use this feature when you are working on different versions of the same copy, and only want to use one for your final draft.
                                            </p>
                                        </>
                                    )}
                                />
                            </li>

                            <li>
                                <DetailsBlock
                                    summary="What are shortcuts?"
                                    details={(
                                        <>
                                            <p>
                                                We know you're in a hurry, and the deadline is looming. To help you quickly draft content, you can insert elements in your document by using shortcuts. Within the insert elements menu, you could use the underlined shortcut key to directly insert an element.
                                            </p>

                                            <blockquote>
                                                For example, press <Keyboard value="P" /> for <u>P</u>aragraph.
                                            </blockquote>

                                            <br />

                                            <p>You can also use a direct shortcut. Here are all the shortcuts you can use.</p>

                                            <table border="0">
                                                <thead>
                                                    <tr>
                                                        <td></td>
                                                        <td>Mac</td>
                                                        <td>Windows</td>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    <tr>
                                                        <td>Copy all</td>
                                                        <td>
                                                            <p>
                                                                <Keyboard value="ctrl ⌃" />
                                                                <Keyboard value="opt ⌥" />
                                                                <Keyboard value="shift ⇧" />
                                                                <Keyboard value="C" />
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <p>
                                                                <Keyboard value="ctrl ⌃" />
                                                                <Keyboard value="alt" />
                                                                <Keyboard value="shift ⇧" />
                                                                <Keyboard value="C" />
                                                            </p>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>Hide/Show Preview</td>
                                                        <td>
                                                            <p>
                                                                <Keyboard value="ctrl ⌃" />
                                                                <Keyboard value="opt ⌥" />
                                                                <Keyboard value="shift ⇧" />
                                                                <Keyboard value="P" />
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <p>
                                                                <Keyboard value="ctrl ⌃" />
                                                                <Keyboard value="alt" />
                                                                <Keyboard value="shift ⇧" />
                                                                <Keyboard value="P" />
                                                            </p>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td colSpan={3}>
                                                            <b>Insert</b>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td><u>H</u>eading</td>
                                                        <td>
                                                            <p>
                                                                <Keyboard value="ctrl ⌃" />
                                                                <Keyboard value="opt ⌥" />
                                                                <Keyboard value="H" />
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <p>
                                                                <Keyboard value="ctrl ⌃" />
                                                                <Keyboard value="alt" />
                                                                <Keyboard value="H" />
                                                            </p>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>S<u>u</u>bheading</td>
                                                        <td>
                                                            <p>
                                                                <Keyboard value="ctrl ⌃" />
                                                                <Keyboard value="opt ⌥" />
                                                                <Keyboard value="U" />
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <p>
                                                                <Keyboard value="ctrl ⌃" />
                                                                <Keyboard value="alt" />
                                                                <Keyboard value="U" />
                                                            </p>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td><u>P</u>aragraph</td>
                                                        <td>
                                                            <p>
                                                                <Keyboard value="ctrl ⌃" />
                                                                <Keyboard value="opt ⌥" />
                                                                <Keyboard value="P" />
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <p>
                                                                <Keyboard value="ctrl ⌃" />
                                                                <Keyboard value="alt" />
                                                                <Keyboard value="P" />
                                                            </p>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td><u>C</u>TA</td>
                                                        <td>
                                                            <p>
                                                                <Keyboard value="ctrl ⌃" />
                                                                <Keyboard value="opt ⌥" />
                                                                <Keyboard value="C" />
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <p>
                                                                <Keyboard value="ctrl ⌃" />
                                                                <Keyboard value="alt" />
                                                                <Keyboard value="C" />
                                                            </p>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>Lin<u>k</u></td>
                                                        <td>
                                                            <p>
                                                                <Keyboard value="ctrl ⌃" />
                                                                <Keyboard value="opt ⌥" />
                                                                <Keyboard value="K" />
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <p>
                                                                <Keyboard value="ctrl ⌃" />
                                                                <Keyboard value="alt" />
                                                                <Keyboard value="K" />
                                                            </p>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td><u>L</u>ist</td>
                                                        <td>
                                                            <p>
                                                                <Keyboard value="ctrl ⌃" />
                                                                <Keyboard value="opt ⌥" />
                                                                <Keyboard value="L" />
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <p>
                                                                <Keyboard value="ctrl ⌃" />
                                                                <Keyboard value="alt" />
                                                                <Keyboard value="L" />
                                                            </p>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td><u>I</u>mage</td>
                                                        <td>
                                                            <p>
                                                                <Keyboard value="ctrl ⌃" />
                                                                <Keyboard value="opt ⌥" />
                                                                <Keyboard value="I" />
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <p>
                                                                <Keyboard value="ctrl ⌃" />
                                                                <Keyboard value="alt" />
                                                                <Keyboard value="I" />
                                                            </p>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td><u>V</u>ideo</td>
                                                        <td>
                                                            <p>
                                                                <Keyboard value="ctrl ⌃" />
                                                                <Keyboard value="opt ⌥" />
                                                                <Keyboard value="V" />
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <p>
                                                                <Keyboard value="ctrl ⌃" />
                                                                <Keyboard value="alt" />
                                                                <Keyboard value="V" />
                                                            </p>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td><u>D</u>ivider</td>
                                                        <td>
                                                            <p>
                                                                <Keyboard value="ctrl ⌃" />
                                                                <Keyboard value="opt ⌥" />
                                                                <Keyboard value="D" />
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <p>
                                                                <Keyboard value="ctrl ⌃" />
                                                                <Keyboard value="alt" />
                                                                <Keyboard value="D" />
                                                            </p>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td><u>T</u>itle</td>
                                                        <td>
                                                            <p>
                                                                <Keyboard value="ctrl ⌃" />
                                                                <Keyboard value="opt ⌥" />
                                                                <Keyboard value="T" />
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <p>
                                                                <Keyboard value="ctrl ⌃" />
                                                                <Keyboard value="alt" />
                                                                <Keyboard value="T" />
                                                            </p>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>Subtitl<u>e</u></td>
                                                        <td>
                                                            <p>
                                                                <Keyboard value="ctrl ⌃" />
                                                                <Keyboard value="opt ⌥" />
                                                                <Keyboard value="E" />
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <p>
                                                                <Keyboard value="ctrl ⌃" />
                                                                <Keyboard value="alt" />
                                                                <Keyboard value="E" />
                                                            </p>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td><u>B</u>lockquote</td>
                                                        <td>
                                                            <p>
                                                                <Keyboard value="ctrl ⌃" />
                                                                <Keyboard value="opt ⌥" />
                                                                <Keyboard value="B" />
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <p>
                                                                <Keyboard value="ctrl ⌃" />
                                                                <Keyboard value="alt" />
                                                                <Keyboard value="B" />
                                                            </p>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td><u>N</u>ote</td>
                                                        <td>
                                                            <p>
                                                                <Keyboard value="ctrl ⌃" />
                                                                <Keyboard value="opt ⌥" />
                                                                <Keyboard value="N" />
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <p>
                                                                <Keyboard value="ctrl ⌃" />
                                                                <Keyboard value="alt" />
                                                                <Keyboard value="N" />
                                                            </p>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td colSpan={3}>
                                                            <b>Remove</b>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td> Element</td>
                                                        <td>
                                                            <p>
                                                                <Keyboard value="ctrl ⌃" />
                                                                <Keyboard value="opt ⌥" />
                                                                <Keyboard value="del" />
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <p>
                                                                <Keyboard value="ctrl ⌃" />
                                                                <Keyboard value="alt" />
                                                                <Keyboard value="del" />
                                                            </p>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </>
                                    )}
                                />
                            </li>

                            <li>
                                <DetailsBlock
                                    summary="How do I export the copy I write?"
                                    details={(
                                        <>
                                            <p>
                                                To export and copy your content, go to Preview and simply click on  <b>Copy all</b>. This will copy your content and you can paste it wherever you'd like - in an email, in a document editor such as Google Docs or Microsoft Word (to forward to your team or keep for yourself), on social media or on your website.
                                            </p>
                                            <p>
                                                The elements that you have marked as hidden will not appear in Preview.
                                            </p>
                                        </>
                                    )}
                                />
                            </li>

                            <li>
                                <DetailsBlock
                                    summary="How do I save the copy I write?"
                                    details={(
                                        <>
                                            <p>
                                                Mangocopy automatically saves your copy as you write. All your copy is saved locally on your device.
                                            </p>
                                        </>
                                    )}
                                />
                            </li>
                        </ul>
                    </section>
                </div>
            </motion.main>
        </>
    )
}

export function DetailsBlock({ open, summary, details }) {

    const handleBlockClick = (e) => {
        if (!e.target.open) return;
        e.target.scrollIntoView({ "behavior": "smooth", "block": "nearest" });
    }
    return (
        <details open={open} onToggle={handleBlockClick}>
            <summary>
                <h4>{summary}</h4>
            </summary>
            {details}
        </details>
    )
}


export function Keyboard({ value }) {
    return (
        <span className="key">{value}</span>
    )
}