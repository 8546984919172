import React, { useContext } from 'react';
import removeIcon from '../../images/icons/remove.svg';
import { ElementsContext } from '../../contexts/ElementsContext';

const RemoveButton = ({ element }) => {

    const { elements, setElements, setFocusedElement } = useContext(ElementsContext);

    const removeElement = () => {
        let currentElements = [...elements];

        let newElements = currentElements.filter(e => e.id !== element.id);
        setElements(newElements);

        setFocusedElement(null);
    };

    return (
        <button className="icon-button remove-button"
            tabIndex={-1}
            onClick={removeElement}
            data-tooltip='Remove'
        >
            <img src={removeIcon} alt="Remove" loading='eager' />
        </button>
    );
}

export default RemoveButton;
