import React from 'react';
import './Home.scss';
import writingHandsEmoji from '../../images/emojis/writing_hand.png';
import heartHandsEmoji from '../../images/emojis/heart_hands.png';
import sparklesEmoji from '../../images/emojis/sparkles.png';
import previewVideo from '../../videos/preview.mp4';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

const currentHour = new Date().getHours();

export default function Home() {
    let greetingTime = "time";

    if (currentHour >= 5 && currentHour < 12) {
        greetingTime = "morning";
    }
    else if (currentHour > 11 && currentHour < 17) {
        greetingTime = "afternoon";
    }
    else if (currentHour > 16 && currentHour <= 23) {
        greetingTime = "evening";
    }
    else {
        greetingTime = "time";
    }

    const animVariants = {
        initial: {
            opacity: 0,
        },
        animate: {
            opacity: 1,
            transition: {
                staggerChildren: 0.1,
                ease: "circOut",
            },
        },
    }

    const introductionListItem = {
        initial: {
            opacity: 0,
            y: 10,
        },
        animate: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 0.6,
                ease: "easeOut",
            },
        },
    }

    return (
        <motion.main
            className="home-main"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ ease: "easeOut", duration: 0.2 }}
        >
            <div className="home-introduction-wrapper">

                <div className="home-introduction">
                    <h1 aria-label="The only copy editor designed for marketers, copywriters, content marketers, performance marketers.">
                        <span aria-hidden="true">
                            The only copy editor designed
                        </span>
                        <span className='line-two'>
                            for
                            <span className='blocks'>
                                <span className="block"> marketers.</span>
                                <span className="block"> copywriters.</span>
                                <span className="block"> content marketers.</span>
                                <span className="block"> performance marketers.</span>
                            </span>
                        </span>

                    </h1>
                    <h2>Make your copy twice as juicy and more efficient with Mangocopy.</h2>

                    <motion.ul variants={animVariants} initial="initial" animate="animate">
                        <motion.li variants={introductionListItem}>
                            <img src={writingHandsEmoji} alt="" loading="lazy" />
                            <p>Write copy in the Editor. <b>Saves&nbsp;automatically.</b></p>
                        </motion.li>

                        <motion.li variants={introductionListItem}>
                            <img src={heartHandsEmoji} alt="" loading="lazy" />
                            <p>See instant Preview <b>as you type.</b><span className="cursor">|</span></p>
                        </motion.li>

                        <motion.li variants={introductionListItem}>
                            <img src={sparklesEmoji} alt="" loading="lazy" />
                            <p>Copy and Share. Anywhere!</p>
                        </motion.li>
                    </motion.ul>

                    <Link to="/write" className='start-btn'>Start writing with Mangocopy</Link>
                    <p className='start-btn-description'><b>It's FREE</b>. Give it a try.</p>
                </div>
            </div>
            <div className="home-preview">
                <video src={previewVideo} muted={true} playsInline={true} autoPlay={true} loop={true}></video>
            </div>
            <p className="greeting">
                <span>Have a sweet {greetingTime} writing<i>!</i>&nbsp;</span>
                <img src='/writing_hand_anim.gif' alt="" loading="lazy" />
            </p>
        </motion.main>
    )
}

