import React, { useEffect, useRef, useState, useContext } from 'react'
import makeHttpsUrl from '../../../functions/makeHttpsUrl';
import { ElementsContext } from '../../../contexts/ElementsContext';
import videoPlaceholderImg from '../../../images/video_placeholder.png';

export const PreviewVideo = ({ element }) => {
    const { styledPreview } = useContext(ElementsContext);

    const srcTimerRef = useRef();
    const [videoSrc, setVideoSrc] = useState(makeHttpsUrl(element.value.trim()));

    useEffect(() => {
        if (!element.value.trim()) return;

        clearTimeout(srcTimerRef.current);
        let url = makeHttpsUrl(element.value.trim());

        if (videoSrc === url) return;

        srcTimerRef.current = setTimeout(() => {
            setVideoSrc(url);
        }, 600);

        return () => {
            clearTimeout(srcTimerRef.current);
        };
    }, [element.value]);

    if (element.isHidden) {
        return null;
    }

    if (!element.value?.trim().length > 0) {
        return null;
    }

    return (
        <div data-editor-id={element.id} className="preview-block link-block">
            <span className='preview-block-label'>{element.previewLabel ? element.previewLabel : element.editorLabel}: </span>

            <a href={videoSrc}
                target="_blank"
                title="Open video in a new tab"
                rel="noreferrer"
            >
                <img src={`https://www.google.com/s2/favicons?domain=${videoSrc}&sz=32`} className="link-block-favicon" alt="" loading="eager" draggable="false" />
                <span>{videoSrc}</span>
            </a>
        </div>
    )
}
export default PreviewVideo;