import React, { useState, useEffect } from 'react'
import mangocopyLogo from '../../images/identity/mangocopy_logo.svg';
import './Header.scss';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import ShareDialog from '../ShareDialog';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import menuIcon from '../../images/icons/menu.svg';
import menuCloseIcon from '../../images/icons/remove.svg';
import { useAuth } from '../../contexts/AuthContext';

export default function Header() {
    const location = useLocation();
    const navigateTo = useNavigate();
    const [shareDialogVisible, setShareDialogVisible] = useState(false);
    const { user, signOut } = useAuth();

    useEffect(() => {
        if (location.hash === "#share") {
            setShareDialogVisible(true);
        }

        // cleanup
        return () => setShareDialogVisible(false);
    }, [location.hash]);

    const [navOpen, setNavOpen] = useState(false);
    const isMobile = useMediaQuery('(max-width: 1000px)');

    const toggleNavMenu = () => {
        setNavOpen(currentState => (!currentState));
    }

    // close the nav menu when route changes
    useEffect(() => {
        if (!isMobile) return;
        if (!navOpen) return;
        setNavOpen(currentState => (false));
    }, [location]);

    const currentPathIs = (path) => {
        return location.pathname === path;
    }

    const handleLogout = () => {
        if (currentPathIs("/write")) {
            let permission = window.confirm('Are you sure you want to logout? Your copy is saved safely on this device and will stay as it is.');
            if (!permission) return;
            signOut();
        }
        else {
            navigateTo("/write");
        }
    }

    let logoutButtonLabel = currentPathIs('/write') ? `Logout ${user?.user_metadata?.full_name}` : `Signed in as ${user?.user_metadata?.full_name}`;


    return (
        <>
            <header>
                <div style={{ display: 'flex' }}>
                    <NavLink to="/" className="header-logo">
                        <img src={mangocopyLogo} alt="Mangocopy" loading='eager' height="22" />
                    </NavLink>
                    {/* {!isMobile && (
                        <>
                            <span>for</span>
                            <select>
                                <option value="blogs">Blogs</option>
                                <option value="google">Google Ads</option>
                                <option value="twitter">Twitter Ads</option>
                            </select>
                        </>
                    )} */}
                </div>

                {isMobile && (
                    <button className={navOpen ? 'nav-toggle-button active' : 'nav-toggle-button'}
                        title={navOpen ? "Close menu" : "Open menu"}
                        onClick={toggleNavMenu}
                    >
                        <img src={navOpen ? menuCloseIcon : menuIcon} alt="Menu" />
                    </button>
                )}

                <nav className={navOpen ? 'overflow' : ''}>
                    {isMobile && (
                        <NavLink to="/">Home</NavLink>
                    )}
                    {!isMobile && (
                        <NavLink to="/write">Write</NavLink>
                    )}
                    <NavLink to="/guides">Guides</NavLink>
                    <NavLink to="/support">Support</NavLink>
                    <Link to="#share">Share</Link>
                    {!isMobile && user && (
                        <button
                            title={logoutButtonLabel}
                            className='logout-btn'
                            onClick={handleLogout}
                        >
                            <img src={user?.user_metadata.avatar_url} alt={user?.user_metadata?.full_name} />
                        </button>
                    )}
                </nav>
            </header>

            {isMobile && navOpen && (
                <div className="nav-skrim" onClick={toggleNavMenu}></div>
            )}

            <ShareDialog open={shareDialogVisible} setShareDialogVisible={setShareDialogVisible} />
        </>
    )
}