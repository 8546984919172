import React, { useState, useRef, useEffect, useContext } from 'react'
import { ElementsContext } from '../../../contexts/ElementsContext';

export default function SavingStatus() {
    const { elements } = useContext(ElementsContext);

    const savingStatus = useRef(false);
    const [isSaving, setIsSaving] = useState(false);

    // save elements data after 600ms
    const savingStatusTimer = useRef();

    useEffect(() => {
        savingStatus.current = true;
        setIsSaving(true);
        clearTimeout(savingStatusTimer.current);

        savingStatusTimer.current = setTimeout(() => {
            localStorage.setItem('elements', JSON.stringify(elements));
            savingStatus.current = false;
            setIsSaving(false);
        }, 600);

        // cleanup
        return () => {
            clearTimeout(savingStatusTimer.current);
            savingStatus.current = false;
            setIsSaving(false);
        };
    }, [elements]);

    // if the page is closed while saving data, show a confirmation alert
    useEffect(() => {
        window.onbeforeunload = function (e) {
            e = e || window.event;
            if (savingStatus.current) {
                // For IE and Firefox prior to version 4
                if (e) {
                    e.returnValue = 'Sure?';
                }

                // For Safari
                return 'Sure?';
            }
        };
    }, []);

    return (
        <>
            {isSaving ? (
                <div className='saving-status'>
                    Saving...
                </div>
            ) : (
                <div className='saving-status'>
                    Saved
                </div>
            )}
        </>
    )
}
