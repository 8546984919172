import { useEffect, useRef } from 'react';

const useKey = (key, callback, useCtrlKey = true, useAltKey = true, useShiftKey = true) => {
    const callbackRef = useRef(callback);

    useEffect(() => {
        callbackRef.current = callback;
    }, []);

    useEffect(() => {
        const handler = (e) => {
            if (e.ctrlKey !== useCtrlKey || e.altKey !== useAltKey || e.shiftKey !== useShiftKey) return;
            if (e.code !== key) return;
            if (e.repeat) return;

            e.preventDefault();

            callbackRef.current(e);
        }

        document.addEventListener('keydown', handler);

        return () => document.removeEventListener('keydown', handler);
    }, [key]);
}

export default useKey;