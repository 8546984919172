import React from 'react';
import './MobileMessage.scss';
import mangocopySymbol from '../../images/identity/mangocopy_symbol.svg';
import { motion } from 'framer-motion';

const MobileMessage = () => {
    return (
        <main className="mobile-message-main">
            <div className="main-message-wrapper">
                <div className="mobile-message-art">
                    <img src={mangocopySymbol} alt="Mangocopy" className="mangocopy-symbol" />
                </div>
                <motion.p
                    className="title"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 2, delay: 0.2, ease: "easeOut" }}
                >We can't wait either<i>!</i></motion.p>
                <motion.p
                    className="desc"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 2, delay: 0.4, ease: "easeOut" }}
                >We are still getting ready. Please visit <u>mangocopy.app</u> from a <b>desktop or a laptop</b> to start writing copy.</motion.p>
            </div>
        </main>
    );
}

export default MobileMessage;
