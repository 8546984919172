import React, { useState, useEffect, useRef, useContext } from 'react';
import './InputElement.scss';
import RemoveButton from '../../RemoveButton';
import DuplicateButton from '../../DuplicateButton';
import CopyTextButton from '../../CopyTextButton';
import InsertGroup from '../InsertGroup';
import { ElementsContext } from '../../../contexts/ElementsContext';
import TextareaAutosize from 'react-autosize-textarea';
import HideButton from '../../HideButton/';
import placeholderCopy from '../../../lib/placeholderCopy';
import { useSortable } from '@dnd-kit/sortable';
import { motion } from 'framer-motion';


const InputElement = ({ ...props }) => {
    const { index, element } = props;
    const { elements, setElements, setFocusedElement, ctaSuggestions, setCtaSuggestions } = useContext(ElementsContext);

    // show insert group if this is the last element
    let showInsertGroup = false;
    if (!element.isGroupChild && elements.length - 1 === index) {
        showInsertGroup = true;
    }

    const isCtaInput = element.editorLabel === "CTA";

    // current value for this element
    const [currentValue, setCurrentValue] = useState(element.value);

    const trimCurrentValue = () => {
        setCurrentValue(current => current.trim());
    }

    const countWords = (value) => {
        let count = value.split(/\s+/).length;
        let word = count === 1 ? 'word' : 'words';
        return `${Intl.NumberFormat('en', { notation: 'compact' }).format(count)} ${word}`;
    }

    const countChars = (value) => {
        let count = value.length;
        let char = count === 1 ? 'char' : 'chars';
        return `${Intl.NumberFormat('en', { notation: 'compact' }).format(count)} ${char}`;
    }

    const [countState, setCountState] = useState(null);
    let countStateTimer = useRef(null);

    useEffect(() => {
        // when value is empty set count state as null
        if (!currentValue) return setCountState(null);

        // clear the timer while typing
        clearTimeout(countStateTimer.current);

        // after typing stops, begin counting
        countStateTimer.current = setTimeout(() => {
            setCountState(`${countWords(currentValue.trim())} ${countChars(currentValue)}`);
        }, 600);

        // cleanup
        return () => {
            clearTimeout(countStateTimer.current);
        }
    }, [currentValue, element.showWordCount]);


    // set value on change
    const handleChange = (e) => {
        setCurrentValue(e.target.value);
    };


    const updateInputValue = () => {
        let currentElements = [...elements];
        element.value = currentValue;
        setElements(currentElements);
    }

    // save the new value after typing finishes
    const handleChangeTimer = useRef(null);
    useEffect(() => {
        clearTimeout(handleChangeTimer.current);

        handleChangeTimer.current = setTimeout(() => {
            updateInputValue();
        }, 200);
    }, [currentValue]);

    const handleFocus = () => {
        setFocusedElement(element.id);
    }

    const handleBlur = () => {
        trimCurrentValue();
        setFocusedElement(null);
        if (isCtaInput) handleCtaSuggestions();
    }

    // get value from cta element
    // add it to the current ctaSuggestions array
    // make a unique array out of the ctaSuggestions array
    const handleCtaSuggestions = () => {
        let elementValue = element.value.trim();
        if (!elementValue) return;

        let currentValues = [...ctaSuggestions];
        currentValues.unshift(elementValue);
        let uniqueValues = [...new Set(currentValues)];

        if (uniqueValues === currentValues) return;
        setCtaSuggestions(uniqueValues);
    }


    const insertMangoCopy = () => {
        if (process.env.NODE_ENV === "production") return;
        let selectedCopy = placeholderCopy[Math.floor(Math.random() * placeholderCopy.length)];
        setCurrentValue(selectedCopy);
    }

    const removeElement = () => {
        let needsPermission = element.value.trim().length > 0;
        let permissionMessage = `Remove this ${element.editorLabel ? element.editorLabel.toLowerCase() : 'element'}? This cannot be undone.`
        let currentElements = [...elements];

        if (needsPermission) {
            let permission = window.confirm(permissionMessage);
            if (!permission) return;
        }

        let newElements = currentElements.filter(e => e.id !== element.id);
        setElements(newElements);

        setFocusedElement(null);
    };

    const [insertShortcut, setInsertShortcut] = useState(null);

    const handleKeyDown = (e) => {
        if (e.repeat) return;

        switch (e.code) {
            case "Backspace":
                if (e.ctrlKey && e.altKey) {
                    e.preventDefault();
                    removeElement();
                    break;
                }
                break;
            default:
                if (e.shiftKey) return;
                if (!e.ctrlKey || !e.altKey) return;
                e.preventDefault();
                setInsertShortcut(e.code);
                break;
        }
    }

    const {
        setNodeRef,
        attributes,
        listeners,
        transform,
        isDragging,
    } = useSortable({
        id: element.id,
        transition: null,
    });

    return (
        <motion.div
            ref={setNodeRef}
            style={{ perspective: isDragging ? 1000 : 'initial' }}
            layout
            animate={
                transform
                    ? {
                        x: transform.x,
                        y: transform.y,
                        zIndex: isDragging ? 1 : '',
                    }
                    : {
                        x: 0,
                        y: 0,
                    }
            }
            transition={{
                duration: transform ? (isDragging ? 0 : 0.45) : 0.25,
                easings: {
                    type: 'tween',
                    duration: isDragging ? 0 : 0.6,
                },
                zIndex: {
                    delay: isDragging ? 0 : 0.1,
                },
            }}
        >
            <motion.div
                className='input-group'
                tabIndex={-1}
                animate={
                    transform
                        ? {
                            scale: isDragging ? 1.05 : 1,
                            rotateX: isDragging ? [null, 30, 0] : 0,
                            rotateY: isDragging ? [null, -2, 0] : 0,
                            boxShadow: isDragging ? '0 0 0 1px #eee, 0 20px 40px 0 rgba(0,0,0,0.15), 0 2px 4px 0 rgba(0,0,0,0.1)' : '0 0 0 1px #e5e5e5',

                        }
                        : {
                            scale: 1,
                            rotateX: 0,
                            rotateY: 0,
                            boxShadow: '',
                        }
                }
                transition={{
                    duration: !isDragging ? 0.3 : 0,
                    easings: {
                        type: 'tween',
                        duration: !isDragging ? 0.6 : 0,
                    },
                    boxShadow: {
                        duration: 0.2,
                        ease: isDragging ? 'backOut' : 'anticipate',
                        delay: 0.05,
                    },
                    scale: {
                        duration: 0.2,
                        ease: isDragging ? 'backOut' : 'anticipate',
                        delay: 0.05,
                    },
                    rotateX: {
                        duration: 0.25,
                        ease: 'backOut',
                    },
                    rotateY: {
                        duration: 0.25,
                        ease: 'backOut',
                    }
                }}
            >
                <div className="label-group">
                    <label htmlFor={element.id}>
                        {element.editorLabel}
                        {element.showWordCount && !element.isHidden && element.value && <span className="word-count">{countState}</span>}
                        {element.isHidden && <span className="hidden-state">Hidden</span>}
                    </label>

                    <div className="drag-handle"
                        {...listeners}
                        {...attributes}
                        tabIndex={-1}
                        title='Click and drag to move...'
                    ></div>

                    <div className={`element-actions ${isDragging ? 'fade-out' : ''}`}>
                        {element.isCopiable && (
                            <CopyTextButton
                                element={element}
                            />
                        )}

                        {element.isDuplicatable && (
                            <DuplicateButton
                                element={element}
                                index={index}
                            />
                        )}

                        {element.isHideable && (
                            <HideButton
                                element={element}
                                index={index}
                            />
                        )}

                        {element.isRemovable && (
                            <RemoveButton
                                element={element} />
                        )}
                    </div>
                </div>

                {element.editorLabel === "CTA" ?
                    (
                        <input
                            type="text"
                            id={element.id}
                            onChange={handleChange}
                            value={currentValue}
                            spellCheck="true"
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            onKeyDown={handleKeyDown}
                            autoComplete="true"
                            list="ctaSuggestionList"
                            onDoubleClick={insertMangoCopy}
                            placeholder={element.placeholder}
                        />
                    ) : (
                        <TextareaAutosize
                            id={element.id}
                            onChange={handleChange}
                            value={isDragging ? `${currentValue.length > 75 ? currentValue.substring(0, 75) + "..." : currentValue}` : currentValue}
                            spellCheck="true"
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            onKeyDown={handleKeyDown}
                            autoComplete="true"
                            onDoubleClick={insertMangoCopy}
                            placeholder={element.placeholder}
                        >
                        </TextareaAutosize>
                    )
                }
            </motion.div>

            <InsertGroup
                element={element}
                index={index}
                insertShortcut={insertShortcut}
                alwaysVisible={showInsertGroup}
            />
        </motion.div>
    )
}

export default InputElement;