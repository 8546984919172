import React, { useState, useEffect } from 'react';
import './NotFound.scss';
import { Link, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';

export default function NotFound() {
    const timerDuration = 10;
    const [timerCount, setTimerCount] = useState(10);
    const navigate = useNavigate();

    useEffect(() => {
        let clock = setInterval(() => {
            setTimerCount(current => current - 1);
        }, 1000);

        let timer = setTimeout(() => {
            navigate("/");
        }, timerDuration * 1000);

        return () => {
            clearInterval(clock);
            clearTimeout(timer);
        };
    }, []);

    return (
        <motion.main className="notfound-main"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ ease: "easeOut", duration: 0.2 }}
        >
            <div className="notfound-main-wrapper">
                <h1>404</h1>
                <h2>Can't find the page you are looking for.</h2>
                <p>Redirecting back to the <Link to="/">homepage</Link> in {timerCount}...</p>
            </div>
        </motion.main>
    )
}
