import React from 'react';

const PreviewDivider = ({ element }) => {
    return (
        <>
            <br />
            <hr className="preview-block divider-block" data-editor-id={element.id} />
        </>
    )
}

export default PreviewDivider;
