import React, { useState, useEffect, useRef } from 'react';

import './App.scss';

import { nanoid } from 'nanoid';
import { AnimatePresence, motion } from 'framer-motion';
import { closestCenter, DndContext, PointerSensor, useSensor } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy, arrayMove } from '@dnd-kit/sortable';
import { restrictToVerticalAxis, restrictToParentElement } from '@dnd-kit/modifiers';
import { ElementsContext } from '../../contexts/ElementsContext';
import { useAuth } from '../../contexts/AuthContext';

import InputElement from '../../components/editorElements/InputElement';
import InsertGroup from '../../components/editorElements/InsertGroup';
import DividerElement from '../../components/editorElements/DividerElement';
import SavingStatus from '../../components/editorElements/SavingStatus';

import PreviewText from '../../components/previewElements/PreviewText';
import PreviewBlock from '../../components/previewElements/PreviewBlock';
import PreviewDivider from '../../components/previewElements/PreviewDivider';
import PreviewLink from '../../components/previewElements/PreviewLink';
import PreviewList from '../../components/previewElements/PreviewList';
import PreviewNote from '../../components/previewElements/PreviewNote';
import PreviewImage from '../../components/previewElements/PreviewImage';
import PreviewVideo from '../../components/previewElements/PreviewVideo';

import TogglePreviewButton from '../../components/TogglePreviewButton/TogglePreviewButton';
import MobileMessage from '../../components/MobileMessage';
import LoginBlock from '../../components/LoginBlock';

import closeIcon from '../../images/icons/remove.svg';

import useKey from '../../hooks/useKey';
import { useMediaQuery } from '../../hooks/useMediaQuery';

import setFocusTo from '../../functions/setFocusTo';

import defaultElementProps from '../../lib/defaultElementProps';


const App = () => {
  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      console.clear();
    }
  }, []);

  const isMobile = useMediaQuery('(max-width: 1000px)');

  const { user } = useAuth();

  const defaultElements = [{
    id: nanoid(),
    ...defaultElementProps,
    editorLabel: 'Title',
    previewLabel: 'Title',
  }, {
    id: nanoid(),
    ...defaultElementProps,
    editorLabel: 'Subtitle',
    previewLabel: 'Subtitle',
  }];

  const [currentTab, setCurrentTab] = useState(() => {
    let storedTab = localStorage.getItem('activeTab');
    if (storedTab) {
      return JSON.parse(storedTab);
    }
    else {
      return 'blog';
    }
  });


  const [elements, setElements] = useState(() => {
    let storedElements = localStorage.getItem('elements');
    if (storedElements) {
      return JSON.parse(storedElements);
    }
    else {
      return defaultElements;
    }
  });


  // On first load...
  // if there are no elements, add default elements
  useEffect(() => {
    if (elements.length === 0) {
      setElements(defaultElements);
      setFocusTo(defaultElements[0]);
    };
  }, []);

  const [recycleBin, setRecycleBin] = useState(null);


  const resetElements = () => {
    if (elements.length) {
      let permission = window.confirm('Are you sure? This will delete all saved copy.');
      if (!permission) return;
    }

    // store everything in recycleBin
    setRecycleBin({
      elements: elements,
      ctaSuggestions: ctaSuggestions,
      focusedElement: focusedElement,
    });

    // clear everything
    setCtaSuggestions([]);
    setFocusedElement(null);
    setElements(defaultElements);
    setFocusTo(defaultElements[0]);

    // show snackbar
    setSnackbar(prev => ({
      ...prev,
      visible: true,
      message: "Editor cleared"
    }));
  };

  const restoreRecycleBin = () => {
    // restore data
    setElements(recycleBin.elements);
    setCtaSuggestions(recycleBin.ctaSuggestions);
    setFocusedElement(recycleBin.focusedElement);

    // scroll back to top
    scrollSectionsToTop();

    // set focus back
    setFocusTo(recycleBin.elements[0]);

    // clear recycle bin
    emptyRecycleBin();
  }

  const emptyRecycleBin = () => {
    setRecycleBin(null);
  };

  const previewContentsRef = useRef(null);
  const [copiedState, setCopiedState] = useState(false);

  const copyToClipboard = () => {
    window.getSelection().selectAllChildren(previewContentsRef.current);
    document.execCommand('copy');
    setCopiedState(true);

    setTimeout(() => {
      setCopiedState(false);
    }, 1000);
  };

  const [focusedElement, setFocusedElement] = useState(() => {
    let storedData = localStorage.getItem('focused_element');
    if (storedData) {
      return storedData;
    }
    else {
      return null;
    }
  });

  useEffect(() => {
    if (focusedElement) {
      localStorage.setItem('focused_element', focusedElement);

      let targetElement = document.querySelector(`[data-editor-id='${focusedElement}']`)
      if (!targetElement) return;

      targetElement.scrollIntoView({ block: "start" });
    }
    else {
      localStorage.removeItem('focused_element');
    }
  }, [focusedElement]);

  useEffect(() => {
    if (focusedElement) {
      setFocusTo({ id: focusedElement });
    }
    else {
      if (!elements.length) return;
      setFocusTo(elements[0]);
    }
  }, []);

  const previewSectionRef = useRef();
  const editorSectionRef = useRef();

  const scrollSectionsToTop = () => {
    editorSectionRef.current.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    if (previewHidden) return;

    previewSectionRef.current.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  // Hide the preview section visually. 
  const [previewHidden, setPreviewHidden] = useState(() => {
    const hidden = localStorage.getItem('preview_hidden');
    return JSON.parse(hidden) ?? false;
  });


  // Suggestions for the CTA element. All unique values are stored and shown in a datalist.
  const [ctaSuggestions, setCtaSuggestions] = useState(() => {
    let suggestions = JSON.parse(localStorage.getItem('cta_suggestions'));
    if (suggestions) {
      return suggestions;
    } else {
      return [];
    }
  });

  // if there are no ctaSuggestions, remove the item from localStorage
  // otherwise save them all to localStorage
  useEffect(() => {
    if (!ctaSuggestions.length) {
      localStorage.removeItem('cta_suggestions');
      return;
    };

    localStorage.setItem('cta_suggestions', JSON.stringify(ctaSuggestions));
  }, [ctaSuggestions]);


  const [snackbar, setSnackbar] = useState({
    visible: false,
    message: null,
  });

  // hide snackbar after 10 seconds
  const snackbarDuration = 10000;

  const snackbarTimer = useRef(null);

  useEffect(() => {
    if (!snackbar.visible) return;
    clearTimeout(snackbarTimer.current);

    snackbarTimer.current = setTimeout(() => {
      dismissSnackbar();
    }, snackbarDuration);

    // cleanup
    return () => {
      clearTimeout(snackbarTimer.current);
    }
  }, [snackbar.visible]);

  const snackbarAnimationVariants = {
    initial: {
      y: 100,
      scale: 0.75,
      rotate: -5,
    },
    animate: {
      y: 0,
      scale: 1,
      rotate: 0,
      transition: {
        duration: 0.4,
        ease: [0, -0.01, 0, 1.2],
      }
    },
    exit: {
      y: 100,
      opacity: 0.5,
      scale: 0.95,
      rotate: 5,
      transition: {
        ease: 'circIn',
        duration: 0.2,
      }
    }
  }

  const dismissSnackbar = () => {
    if (snackbarTimer.current) clearTimeout(snackbarTimer.current);

    setSnackbar(prev => ({
      ...prev,
      visible: false,
      message: null,
    }));
  };

  const undoResetElements = () => {
    dismissSnackbar();
    restoreRecycleBin();
  };

  const dismissResetSnackbar = () => {
    dismissSnackbar();
    emptyRecycleBin();
  };

  useKey("KeyP", () => {
    setPreviewHidden(prev => (!prev));
  });

  useKey("KeyC", () => {
    copyToClipboard();
  });


  // apply formatting to preview elements based on their element label
  const [styledPreview, setStyledPreview] = useState(() => {
    const storedData = localStorage.getItem('styledPreview');
    return JSON.parse(storedData) ?? true;
  });

  const enableStyledPreviews = () => {
    setStyledPreview(current => true);
  }

  const disableStyledPreviews = () => {
    setStyledPreview(current => false);
  }

  useEffect(() => {
    localStorage.setItem('styledPreview', styledPreview);
  }, [styledPreview]);

  // reorder elements in the editor
  const editorSensors = [useSensor(PointerSensor)];
  const [isReordering, setReordering] = useState(false);

  const handleDragStart = () => {
    setReordering(current => true);
  }

  const reorderElements = (event) => {
    setReordering(current => false);
    const { active, over } = event;

    if (active.id === over.id) return;
    setElements((items) => {
      const oldIndex = items.findIndex(item => item.id === active.id);
      const newIndex = items.findIndex(item => item.id === over.id);

      return arrayMove(items, oldIndex, newIndex);
    });
  }

  return (
    <>
      {isMobile ? (
        <MobileMessage />
      ) : (
        user ? (
          <ElementsContext.Provider value={{ elements, setElements, focusedElement, setFocusedElement, ctaSuggestions, setCtaSuggestions, styledPreview, isReordering }}>
            <motion.main className={previewHidden ? 'editor-only' : ''}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ ease: "easeOut", duration: 0.2 }}
            >
              <section className="section preview" ref={previewSectionRef}>

                <div className='header-block preview-header'>
                  <div className="title-group">
                    <div className="preview-title-tabs">
                      <h2 onClick={enableStyledPreviews}
                        className={`preview-title-tab ${styledPreview ? 'active' : ''}`}
                      >
                        Preview
                      </h2>
                      <h2 onClick={disableStyledPreviews}
                        className={`preview-title-tab ${styledPreview ? '' : 'active'}`}
                      >
                        Draft
                      </h2>
                    </div>
                  </div>
                  <div className="actions">
                    <button
                      title="Copy to clipboard [Ctrl + Alt/Opt + Shift + C]"
                      className={`primary-button copy-button ${copiedState ? 'copied' : ''}`}
                      onClick={() => { copyToClipboard() }}>Copy all</button>
                  </div>
                </div>


                <div className={`preview-contents ${styledPreview ? '' : 'draft'}`}
                  ref={previewContentsRef}
                >
                  {elements.map((element, index) => {
                    switch (element.previewElement) {
                      case "text":
                        return <PreviewText element={element} key={element.id} />
                      case "block":
                        return <PreviewBlock element={element} key={element.id} />
                      case "divider":
                        return <PreviewDivider element={element} key={element.id} />
                      case "link":
                        return <PreviewLink element={element} key={element.id} />
                      case "list":
                        return <PreviewList element={element} key={element.id} />
                      case "image":
                        return <PreviewImage element={element} key={element.id} />
                      case "video":
                        return <PreviewVideo element={element} key={element.id} />
                      case "note":
                        return <PreviewNote element={element} key={element.id} />
                      default:
                        return null;
                    }
                  })}
                </div>
              </section>

              <section className={previewHidden ? 'section editor solo' : 'section editor'} ref={editorSectionRef}>

                <div className="header-block editor-header">
                  <div className="title-group">
                    <h2>
                      <span className='header-title-text'>Editor</span>
                    </h2>

                    {/* store data after 600ms and show saving status */}
                    <SavingStatus />
                  </div>

                  <div className="actions">
                    <button className="primary-button reset-button" onClick={() => { resetElements() }} tabIndex="-1">Clear</button>
                    {previewHidden && (
                      <button className={`primary-button copy-button ${copiedState ? 'copied' : ''}`} onClick={() => { copyToClipboard() }} tabIndex="1">Copy all</button>
                    )}
                  </div>
                </div>

                {elements.length === 0 ? (
                  <InsertGroup
                    alwaysVisible="true"
                  />
                ) : (
                  <InsertGroup
                    focusElement={elements[0]}
                  />
                )}

                <AnimatePresence>
                  <DndContext
                    sensors={editorSensors}
                    collisionDetection={closestCenter}
                    onDragStart={handleDragStart}
                    onDragEnd={reorderElements}
                    modifiers={[restrictToVerticalAxis, restrictToParentElement]}
                  >
                    <SortableContext
                      items={elements.map(item => item.id)}
                      strategy={verticalListSortingStrategy}
                    >
                      {elements.map((element, index) => {
                        switch (element.editorElement) {
                          case 'input':
                            return (
                              <InputElement
                                index={index}
                                element={element}
                                key={element.id}
                              />
                            )
                          case 'divider':
                            return (
                              <DividerElement
                                index={index}
                                element={element}
                                key={element.id}
                              />
                            )
                          default:
                            return null;
                        }
                      })}
                    </SortableContext>
                  </DndContext>
                </AnimatePresence>


                <datalist id="ctaSuggestionList">
                  {ctaSuggestions.map((item) => {
                    return (
                      <option value={item} key={item} />
                    )
                  })}
                </datalist>

                <TogglePreviewButton
                  previewHidden={previewHidden}
                  setPreviewHidden={setPreviewHidden}
                />
              </section>

              <div className="snackbar-wrapper">
                <AnimatePresence>
                  {snackbar.visible && snackbar.message && (
                    <motion.div className="snackbar"
                      variants={snackbarAnimationVariants}
                      initial="initial"
                      animate="animate"
                      exit="exit"
                    >
                      <span className="snackbar-message">
                        {snackbar.message}
                      </span>
                      <div className="snackbar-actions">
                        <button className="snackbar-action-button" onClick={undoResetElements}>Undo</button>
                        <button className="snackbar-action-button" onClick={dismissResetSnackbar}>
                          <img src={closeIcon} alt="" loading='eager' />
                        </button>
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            </motion.main>
          </ElementsContext.Provider >
        ) : (
          <LoginBlock />
        )
      )}
    </>

  );
}

export default App;
